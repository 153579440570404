import * as React from "react";
import MainLayout from "../layouts/main-layout";
import styled from "styled-components";
import { useResponsive } from "../theme/responsive-hook";
import { color, greaterOrEqual } from "../theme/theme.utils";
import SocialBar from "../components/social-bar";
import HeroImage from "../images/hero-image.png";

const StyledHomeWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  overflow: hidden;
  align-items: center;

  ${greaterOrEqual("lg")} {
    grid-template-columns: 1fr 1fr;
  }
`;

const StyledHeroImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: url(${HeroImage});
  background-size: cover;
  background-position: center;

  img {
    min-height: 100%;
    min-width: 100%;
  }
`;

const StyledTextWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 48px;
  justify-content: center;

  h1 {
    font-size: 2.5rem;
    margin: 24px 0;
    font-weight: 800;
  }

  h2 {
    font-size: 1.8rem;
    color: ${color.grey};
    font-weight: 400;
    margin: 24px 0;
  }

  strong {
    color: ${color.gold};
  }
`;

const StyledAboutText = styled.p`
  font-size: 1.2rem;
  color: ${color.grey};
  margin: 16px 0 24px 0;
`;

const StyledHandWriteSignature = styled.p`
  color: ${color.black};
  font-size: 1.6rem;
  font-family: Mr Dafoe, sans-serif;
`;

const StyledSocialBar = styled(SocialBar)`
  margin: 24px 0;
`;

const IndexPage = () => {
  const { isLarge } = useResponsive();

  return (
    <main>
      <MainLayout>
        <StyledHomeWrapper>
          {isLarge && <StyledHeroImageWrapper />}
          <StyledTextWrapper>
            <h2>Witaj! Jesteśmy</h2>
            <h1>
              Cam Story <strong>Film i Fotografia</strong> Ślubna
            </h1>
            <StyledAboutText>
              Filmowanie & Fotografia - pozwól nam uwiecznić twoje marzenia.
              Zajmujemy się filmowaniem oraz fotografią w oparciu o
              najnowocześniejszy sprzęt w skład których wchodzą m.in. systemy
              stabilizacji dla urządzeń rejestrujących obraz czy drony.
            </StyledAboutText>
            <StyledHandWriteSignature>CamStory</StyledHandWriteSignature>
            <StyledSocialBar position="left" size="1.3rem" />
          </StyledTextWrapper>
        </StyledHomeWrapper>
      </MainLayout>
    </main>
  );
};

export default IndexPage;
